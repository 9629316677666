import { render, staticRenderFns } from "./ExerciceDetails.vue?vue&type=template&id=dca737ac&scoped=true&"
import script from "./ExerciceDetails.vue?vue&type=script&lang=js&"
export * from "./ExerciceDetails.vue?vue&type=script&lang=js&"
import style0 from "./ExerciceDetails.vue?vue&type=style&index=0&id=dca737ac&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dca737ac",
  null
  
)

export default component.exports
<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <div
    v-else-if="forbidden"
    class="img-container"
  >
    <div>
      <h2>Vous n'avez pas accès à ceci</h2>
      <img :src="require('@/assets/forbidden.png', )" />
      <a
        class="blue-link"
        @click="$router.go(-1,)"
      >
        Retour
      </a>
    </div>
  </div>
  <div v-else-if="exercice">
    <router-link
      class="blue-link"
      :to="{name:'exercices', }"
    >
      <i class="fa fa-arrow-left" />
      Retourner à la liste des exercices
    </router-link>
    <h2>
      <template v-if="isNew">
        Création d'un exercice
      </template>
      <template v-else-if="!disabled">
        Modifier l'exercice "{{oldExerciceName}}"
      </template>
      <template v-else>
        {{oldExerciceName}}
      </template>
    </h2>
    <form>
      <div
        v-if="isAdmin"
        class="form-field public"
      >
        <label>Admin: rendre accessible à tout le monde&nbsp;:</label>
        <div class="field no-border">
          <input
            v-model="exercice.new_public"
            type="checkbox"
            :disabled="disabled"
          />
        </div>
      </div>
      <FloatingInput
        label="Nom"
        v-model="exercice.nom"
        :disabled="disabled"
      />
      <FloatingInput
        v-for="a in 4"
        :key="a"
        :label="`Image${a}`"
        :disabled="disabled"
        type="file"
        v-model="exercice[`img${a}`]"
        @delete-file="exercice[`img${a}`] = ''"
      />
      <FloatingInput
        label="Informations"
        v-model="exercice.infos"
        :disabled="disabled"
        type="textarea"
      />
      <div class="form-field block">
        <label>Muscles solicités&nbsp;:</label>
        <VueBodyPartSelector
          v-model="selection"
          class="human-body"
          :disabled="disabled"
        />
      </div>
    </form>
    <template v-if="exercice.public && isAdmin && !forceEdit">
      <div class="button-container">
        <button @click="forceEdit = true">Admin: Activer l'édition</button>
      </div>
    </template>
    <template v-else-if="!disabled">
      <div class="button-container">
        <button @click="saveExo">Enregistrer</button>
      </div>
      <ValidationButton
        v-if="!isNew"
        text="Annuler"
        secondary
        @validated="$router.push({name:'exercices', }, )"
      />

      <div class="button-container suppr-exo">
        <button
          @click="deleting"
          class="secondary"
        >
          Supprimer l'exercice
        </button>
      </div>
      <div
        v-if="links"
        class="links"
      >
        <h2 v-if="links && links.length">
          Cet exercice est lié aux séances suivantes
        </h2>
        <h2 v-else>
          Cet exercice n'est lié à aucune séance
        </h2>
        <ul>
          <li
            v-for="link in links"
            :key="link"
          >
            {{ link }}
          </li>
        </ul>
        <div class="button-container">
          <button
            @click="deleteExercice"
          >
            Confirmer la suppression
          </button>
        </div>
      </div>
    </template>
  </div>
  <div v-else>
    Chargement
  </div>
</template>

<script>
import Api from "@/modules/axios";
import { catchError } from "@/utils";
import VueBodyPartSelector from "@/components/VueBodyPartSelector.vue";
import ValidationButton from "@/components/ValidationButton.vue";
import { mapGetters } from "vuex";
import FloatingInput from "@/components/FloatingInput.vue";

export default {
  name: "SeanceDetails",
  components: {
    VueBodyPartSelector,
    ValidationButton,
    FloatingInput,
  },
  data() {
    return {
      oldExerciceName: null,
      exercice: null,
      addedExo: null,
      loading: false,
      links: null,
      selection: {},
      forceEdit: false,
      forbidden: false,
    };
  },
  watch: {
    "$route.params.id": {
      handler() {
        this.getInfosExo();
      },
      immediate: true,
    },
  },
  computed: {
    ...mapGetters(["isAdmin", "user"]),
    isNew() {
      return this.$route.params.id === "new";
    },
    disabled() {
      const notMine = this.exercice.user_created && this.exercice.user_created.id !== this.user.id;
      const isPublic = this.exercice.public;
      const adminIsEditing = this.isAdmin && this.forceEdit;

      return notMine || (isPublic && !adminIsEditing);
    },
  },
  mounted() {
    if (!this.user.beta_accepted) {
      alert("Vous n'êtes pas autorisé à accéder à cette page");
      return this.$router.push({ name: "accueil" });
    }
    return true;
  },
  methods: {
    getInfosExo() {
      this.forbidden = false;
      if (this.isNew) {
        this.exercice = {};
        this.loading = false;
      } else {
        this.loading = true;
        Api().get(`/exercice/${this.$route.params.id}/?no_br=1`)
          .then((res) => {
            this.exercice = res.data;
            this.$set(this.exercice, "new_public", this.exercice.public);
            if (this.exercice.muscles_1) {
              this.exercice.muscles_1.split(",").forEach((m) => {
                this.selection[m] = "primary";
              });
            }
            if (this.exercice.muscles_2) {
              this.exercice.muscles_2.split(",").forEach((m) => {
                this.selection[m] = "secondary";
              });
            }

            this.oldExerciceName = res.data.nom;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.forbidden = true;
          });
      }
    },
    saveExo() {
      const tmpData = { ...this.exercice };
      tmpData.public = tmpData.new_public || false;
      delete tmpData.user_created;

      const data = new FormData();
      Object.entries(tmpData).forEach(([key, val]) => {
        let tmpVal = val;
        if (tmpVal === null) {
          tmpVal = "";
        }

        if (!key.includes("img") || (key.includes("img") && (val === "" || val instanceof Object))) {
          data.append(key, tmpVal);
        }
      });

      data.append(
        "muscles_1",
        Object.entries(this.selection).filter(
          (e) => e[1] === "primary",
        ).map((e) => e[0]).join(","),
      );

      data.append(
        "muscles_2",
        Object.entries(this.selection).filter(
          (e) => e[1] === "secondary",
        ).map((e) => e[0]).join(","),
      );

      let promise = null;

      if (this.isNew) {
        promise = Api().post("/exercice/", data);
      } else {
        promise = Api().patch(`/exercice/${this.exercice.id}/`, data);
      }
      promise
        .then((res) => {
          if (this.isNew) {
            this.$router.push({ name: "exercice-details", params: { id: res.data.id } });
          } else {
            this.getInfosExo();
          }
          this.$toast("Exercice sauvegardé");
          this.forceEdit = false;
        })
        .catch(catchError);
    },
    deleting() {
      this.links = null;

      Api().get(`/exercice/${this.exercice.id}/links/`)
        .then((res) => {
          this.links = res.data.seances;
        });
    },
    deleteExercice() {
      Api().delete(`/exercice/${this.exercice.id}/`)
        .then(() => {
          this.$router.push({ name: "exercices" });
          this.$toast("Exercice supprimé");
        })
        .catch(catchError);
    },
    onFileChange(e, id) {
      this.exercice[`img${id}`] = (e.target.files || e.dataTransfer.files)[0];
    },
  },
};
</script>

<style lang="scss" scoped>
@use "@/assets/variables.scss" as var;

.historique {
  margin-top: 2rem;
  background:var.$background-gray;
  padding: 1rem;
  border-radius: var.$border-radius;
}

.exo-order {
  margin-top: 1rem;
}

.links {
  margin-top: 5rem;
  ul {
    padding: unset;
    padding-left: 3rem;
    list-style: unset;
  }
}

.page-exercice-details {
  .body-part-selector {
    margin-top: 1rem;
  }
  .field {
    word-break: break-all
  }
}

.suppr-exo {
  margin-top: 2rem;
}
</style>

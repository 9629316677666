<template>
  <PulseLoader
    v-if="loading"
    loading
  />
  <DeletedElementsList
    v-else-if="displayDeleted"
    title="Exercices supprimées"
    model="exercice"
    @cancel="displayDeleted=false; getInfos()"
  />
  <div v-else>
    <h2>
      Liste des exercices
    </h2>
    <div
      v-if="exercices.length > 5"
      class="button-container top"
    >
      <router-link :to="{name: 'exercice-details', params: {id: 'new', },}">
        <button>Créer un exercice</button>
      </router-link>
    </div>
    <div class="search-bar">
      <input
        class=""
        v-model="searchText"
        type="text"
        placeholder="Rechercher un exercice par son nom"
        @keyup.enter="currentPage=1;getInfos()"
      />
      <i
        class="fa fa-search"
        @click="currentPage=1;getInfos()"
      />
    </div>
    <ul
      v-if="exercices.length"
      class="exercices"
    >
      <li
        v-for="exercice in exercices"
        :key="exercice.id"
      >
          <!-- :is="exercice.public ? 'a' : 'router-link'" -->
        <router-link
          :to="{name: 'exercice-details', params: {id: exercice.id, }, }"
        >
          <div class="details">
            <strong :class="{ 'private': !exercice.public, }">
              {{ exercice.nom }}
            </strong>
            <div v-if="exercice.public">Fourni par l'Absolu</div>
            <div v-else-if="!exercice.public && exercice.user_created.id !== user.id">
              Partagé par {{exercice.user_created.first_name}} {{exercice.user_created.last_name}}
            </div>
          </div>
          <MenuDropdown
            :options="[
              {
                title: (!exercice.public && exercice.user_created.id === user.id) ? 'Modifier' : 'Consulter',
                handler: () => $router.push({ name: 'exercice-details', params: {id: exercice.id, }, }, ),
                icon: 'fa-eye',
              },
              {
                title: 'Historique',
                handler: () => $router.push({name: 'exercice-stats', params: {id: exercice.id, }, }, ),
                icon: 'fa-chart-line',
              },
              (!exercice.public && exercice.user_created.id === user.id) ? {
                title: 'Archiver',
                handler: () => archiveExercice(exercice, ),
                icon: 'fa-trash-alt',
              } : null,
            ]"
          />
        </router-link>
      </li>
    </ul>
    <div
      v-else
      class="img-container tiny"
    >
      <div>
        <h2>Aucun exercice {{searched ? "ne correspond à votre recherche" : ""}}</h2>
        <img :src="require('@/assets/empty.png', )" />
      </div>
    </div>

    <div class="button-container">
      <router-link :to="{name: 'exercice-details', params: {id: 'new', },}">
        <button>Créer un exercice</button>
      </router-link>
    </div>
    <Pagination
      v-model="currentPage"
      :total-results="resultsCount"
      @paginate="getInfos"
    />
    <div class="display-deleted">
      <button @click="displayDeleted=true">
        Afficher les éléments supprimés
      </button>
    </div>
  </div>
</template>

<script>
import DeletedElementsList from "@/components/DeletedElementsList.vue";
import Pagination from "@/components/Pagination.vue";
import Api from "@/modules/axios";
import MenuDropdown from "@/components/MenuDropdown.vue";
import { mapGetters } from "vuex";
import { catchError } from "@/utils";

export default {
  name: "Exercices",
  components: {
    DeletedElementsList,
    Pagination,
    MenuDropdown,
  },
  data() {
    return {
      exercices: [],
      loading: false,
      displayDeleted: false,

      searched: false,
      searchText: "",
      currentPage: 1,
      resultsCount: 0,
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  mounted() {
    if (!this.user.beta_accepted) {
      alert("Vous n'êtes pas autorisé à accéder à cette page");
      return this.$router.push({ name: "accueil" });
    } else {
      this.$store.commit("setAvatarShow", false);
      this.getInfos();
      return true;
    }
  },
  methods: {
    getInfos() {
      this.loading = true;

      Api()
        .get(`/exercice/?search=${this.searchText}&page=${this.currentPage}&page_size=15`)
        .then((res) => {
          this.searched = !!this.searchText;
          this.exercices = res.data.results;
          this.resultsCount = res.data.count;
          this.loading = false;
        });
    },
    archiveExercice(exercice) {
      Api().delete(`/exercice/${exercice.id}/`)
        .then(() => {
          this.$toast("Exercice archivé");
          this.getInfos();
        })
        .catch(catchError);
    },
  },
};
</script>

<style lang="scss">
@use "@/assets/variables.scss" as var;

.exercices {
  li + li {
    margin-top: 1rem;
  }
  li > a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background:var.$background-gray;
    padding: 1rem;
    border-radius: var.$border-radius;
    cursor: pointer;
    // .fa {
    //   background: var.$red;
    //   color: white;
    //   padding: 1rem;
    //   font-size: 1rem;
    //   border-radius: var.$border-radius;
    // }
    .details {
      margin-left: 1rem;
    }
    > *:nth-child(1) {
      width: 50%;
      &.private {
        width: 80%;
      }
    }
    > *:nth-child(2) {
      width: 30%;
      text-align: center;
    }
    > *:last-child {
      width: 20%;
      text-align: right;
    }
  }
}
</style>
